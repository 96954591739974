var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"logo text-center"},[_c('a',{attrs:{"href":"https://www.dentrodahistoria.com.br/","target":"_blank"}},[_c('img',{attrs:{"width":"127","src":require('@/assets/logo-ddh-blue@2x.png'),"alt":"Dentro Educa"}})])]),_c('h3',[_vm._v("Área dos pais e familiares")]),_c('h5',[_vm._v("Para fazer o seu primeiro login você precisa estar com o ID da criança em mãos.")]),_c('form',{staticClass:"va-row",on:{"submit":function($event){$event.preventDefault();return _vm.doLogin.apply(null, arguments)}}},[_c('div',{staticClass:"flex xs12"},[_c('div',{staticClass:"input-group"},[_c('input-normal',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"text","label":"Digite o código do aluno","id":"student_code","name":"student_code","disabled":false,"data-vv-as":'usuário',"value":_vm.login.student_code,"error":_vm.errors.first('student_code')},on:{"input":function($event){_vm.login.student_code = $event.target.value}}}),_c('select-normal',{attrs:{"items":[
            {'key': 'A', 'value': 'A'},
            {'key': 'B', 'value': 'B'},
            {'key': 'C', 'value': 'C'},
            {'key': 'D', 'value': 'D'},
            {'key': 'E', 'value': 'E'},
            {'key': 'F', 'value': 'F'},
            {'key': 'G', 'value': 'G'},
            {'key': 'H', 'value': 'H'},
            {'key': 'I', 'value': 'I'},
            {'key': 'J', 'value': 'J'},
            {'key': 'K', 'value': 'K'},
            {'key': 'L', 'value': 'L'},
            {'key': 'M', 'value': 'M'},
            {'key': 'N', 'value': 'N'},
            {'key': 'O', 'value': 'O'},
            {'key': 'P', 'value': 'P'},
            {'key': 'Q', 'value': 'Q'},
            {'key': 'R', 'value': 'R'},
            {'key': 'S', 'value': 'S'},
            {'key': 'T', 'value': 'T'},
            {'key': 'U', 'value': 'U'},
            {'key': 'V', 'value': 'V'},
            {'key': 'X', 'value': 'X'},
            {'key': 'W', 'value': 'W'},
            {'key': 'Y', 'value': 'Y'},
            {'key': 'Z', 'value': 'Z'}
          ],"value":'',"label":"Primeira letra do nome da criança","name":"typeName","error":_vm.errors.first('escola')},on:{"change":function($event){return _vm.onChange($event)}}}),_c('div',{staticClass:"error text-center"},[_vm._v(_vm._s(_vm.errors.first('error')))])],1)]),_c('div',{staticClass:"d-flex align--center justify--space-between flex xs12"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":false}},[_vm._v("Entrar")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }